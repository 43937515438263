import Contact from "../Contact/Contact";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import "./Projects.scss";
// import smartTransportBanner from "./../../assets/images/smart_transport_banner.svg";
import ihawkBanner from "./../../assets/images/iHawk_custom_banner.svg";
import nlpBanner from "./../../assets/images/nlp_data_analysis.png";
import starEdutainmentBanner from "./../../assets/images/Star Edutainment/Banner-thumb.png";
import cmsBanner from "./../../assets/images/CMS/Login/login – with client.png";

function Projects() {
  let navigate = useNavigate();
  const hawkTradePage = () => {
    setTimeout(() => {
      navigate("/project/hawk-trade");
    }, 300);
  };
  const starEdutainmentPage = () => {
    setTimeout(() => {
      navigate("/project/star-edutainment");
    }, 300);
  };
  const cmsPage = () => {
    setTimeout(() => {
      navigate("/project/container-yard-management-system");
    }, 300);
  };
  // const smartTransportPage = () => {
  //   setTimeout(() => {
  //     navigate("/project/smart-transport");
  //   }, 300);
  // };
  // const smartYardPage = () => {
  //   setTimeout(() => {
  //     navigate("/project/smart-yard");
  //   }, 300);
  // };
  const ihawkPage = () => {
    setTimeout(() => {
      navigate("/project/ihawk");
    }, 300);
  };
  const openPage = () => {
    window.open(
      "https://dribbble.com/shots/20689849-NLP-Data-Analysis-Flow-Concept?utm_source=Clipboard_Shot&utm_campaign=imsam&utm_content=NLP%20Data%20Analysis%20Flow%20Concept&utm_medium=Social_Share&utm_source=Clipboard_Shot&utm_campaign=imsam&utm_content=NLP%20Data%20Analysis%20Flow%20Concept&utm_medium=Social_Share",
      "_blank"
    );
  };
  return (
    <section className="recent-projects">
      <div className="projects_grid">
        <div className="project-card bg-gray-1">
          <div className="project-screen">
            <img
              className="hawk_trade_banner"
              src={require("./../../assets/images/hawk_trade_banner_1.png")}
              alt="Hawk Trade"
            />
          </div>
          <div className="project-details">
            <div>
              <div className="project-name">Hawk Trade</div>
              <div className="project-description">
                An Ultimate Container Booking Solution
              </div>
            </div>
            <Button onClick={hawkTradePage}>View Case Study</Button>
          </div>
        </div>
        <div className="project-card bg-gray-6">
          <div className="project-screen">
            <img
              className="star-edutainment-img gray-banner-border"
              src={starEdutainmentBanner}
              alt="Star Edutainment"
            />
          </div>
          <div className="project-details">
            <div>
              <div className="project-name">Star Edutainment</div>
              <div className="project-description">
                Celebrity Learning & Entertainment App
              </div>
            </div>
            <Button onClick={starEdutainmentPage}>View Case Study</Button>
          </div>
        </div>

        <div className="project-card bg-gray-7">
          <div className="project-screen">
            <img className="cms-img" src={cmsBanner} alt="CMS" />
          </div>
          <div className="project-details">
            <div>
              <div className="project-name fw-light">CMS</div>
              <div className="project-description mb-5 fw-light">
                Container Yard Management System
              </div>
            </div>
            <Button onClick={cmsPage}>View Case Study</Button>
          </div>
        </div>

        <div className="project-card bg-gray-2">
          <div className="project-screen">
            <img className="ihawk-img" src={ihawkBanner} alt="iHawk" />
          </div>
          <div className="project-details">
            <div>
              <div className="project-name fw-light">iHawk Custom</div>
              <div className="project-description mb-5 fw-light">
                Container Management App For Custom
              </div>
            </div>
            <Button onClick={ihawkPage}>View Case Study</Button>
          </div>
        </div>

        <div className="project-card bg-gray-5">
          <div className="project-screen">
            <img className="nlp-img" src={nlpBanner} alt="nlp_analysis" />
          </div>
          <div className="project-details">
            <div>
              <div className="project-name fw-light">
                NLP Data Analysis Concept
              </div>
              <div className="project-description mb-5 fw-light">
                A Platform to Perform Data Analysis
              </div>
            </div>
            <Button onClick={openPage}>View Case Study</Button>
          </div>
        </div>
        {/* <div className="project-card bg-gray-3">
          <div className="project-screen">
            <img
              className="smart-transport-img"
              src={smartTransportBanner}
              alt="Smart Transport"
            />
          </div>
          <div className="project-details">
            <div>
              <div className="project-name">Smart Transport</div>
              <div className="project-description">
                Fleet & Container Tracking App
              </div>
            </div>
            <Button onClick={smartTransportPage} disabled>
              Coming Soon
            </Button>
          </div>
        </div> */}
        {/* <div className="project-card bg-gray-4">
          <div className="project-screen">
            <img
              style={{ maxHeight: "405px" }}
              className="black-banner-border"
              src={require("./../../assets/images/Smart Yard Banner.png")}
              alt="Smart Yard"
            />
          </div>
          <div className="project-details">
            <div>
              <div className="project-name">Smart Yard</div>
              <div className="project-description">
                Yard Automation Solution
              </div>
            </div>
            <Button onClick={smartYardPage} disabled>
              Coming Soon
            </Button>
          </div>
        </div> */}
      </div>

      <Contact fromProjects="true" />
    </section>
  );
}
export default Projects;
